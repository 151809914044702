import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';
import { LoginService } from '../login.service';
import { browserRefresh } from '../app.component';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit {
  public browserRefresh: boolean;
  public role: string;
  public isAdmin: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver,
    private router: Router,
    private loginService: LoginService) { }

  ngOnInit(): void {
    this.browserRefresh = browserRefresh;
    this.getUserRole();
  }

  getUserRole() {
    let userObj = JSON.parse(this.loginService.getLoggedInUserName());
    this.role = userObj.role;
    if (this.role == 'Admin') this.isAdmin = true;
    this.roleModel();
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  roleModel() {
    this.router.navigate(['/home/roles']);
  }

  dependencyModel() {
    this.router.navigate(['/home/dependency']);
  }

  lfThModel() {
    this.router.navigate(['/home/emails']);
  }

  adminModel() {
    this.router.navigate(['/home/admin']);
  }

  tsoModel () {
    this.router.navigate(['/home/tso']);
  }

  rsModel () {
    this.router.navigate(['/home/rs']);
  }

  logOut() {
    this.loginService.logout();
    this.router.navigate(['/login']);
  }

}
