import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RoleMatrixServiceService } from '../../app/role-matrix-service.service';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-role-dialog-box',
  templateUrl: './role-dialog-box.component.html',
  styleUrls: ['./role-dialog-box.component.css']
})
export class RoleDialogBoxComponent {

  action: string;
  modelName: string;
  local_data: any;
  clicked = false;
  id: BigInteger;

  constructor(
    public dialogRef: MatDialogRef<RoleDialogBoxComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData,
    private roleMatrixService: RoleMatrixServiceService,
    private commonService: CommonService) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    this.id = this.local_data.id;
    console.log( this.local_data );
    this.modelName = this.local_data.name;
    delete this.local_data['action'];
    delete this.local_data['id'];
    delete this.local_data['name'];
  }

  doAction() {
    (<HTMLInputElement>document.getElementById('updateButton')).disabled = true;
    if ( this.action === "Update" ) {
      let newObj = {};
      let keys = Object.keys(this.local_data);
      for(let i=0; i < keys.length; i++) {
        let key = keys[i];
        let val = (<HTMLInputElement>document.getElementById(keys[i])).value;
        newObj[key] = val;
      }
      this.local_data = newObj;

      this.commonService.updateRecord(this.id, this.modelName,this.local_data).subscribe((data) => {
        this.dialogRef.close({ event: this.action, data: data });
      }, () => {
        alert("Unexpected error occurred, please try after sometime.");
      });
    } else if (this.action === "Delete") {
      this.commonService.deleteRecord(this.id,this.modelName).subscribe(() => {
        this.dialogRef.close({ event: this.action, data: this.local_data });
      }, () => {
        alert("Unexpected error occurred, please try after sometime.");
      });
    } else if (this.action === "DatabaseDelete") {
      this.commonService.deleteRecords(this.modelName).subscribe(() => {
        this.dialogRef.close({ event: this.action, data: this.local_data });
      }, () => {
        alert("Unexpected error occurred, please try after sometime.");
      });
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}

export interface UsersData {
  id: number;
  call_classification: string;
  cluster: string;
  sub_cluster: string;
  profit_center: string;
  nature_call: string;
  sla: string;
  th_sla: string;
  type_function: string;
  lf_role: string;
  th_role: string;
  th2_role: string;
}
