import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Environment } from './envionment';

@Injectable({
  providedIn: 'root'
})
export class RoleMatrixServiceService {
  private baseUrl = `${Environment.apiurl}/roles/all_roles`;
  private createRoleURL = `${Environment.apiurl}/roles/add`;
  private getSingleRoleURL = `${Environment.apiurl}/roles`;
  private deleteRoleURL = `${Environment.apiurl}/roles`;
  private updateRoleURL = `${Environment.apiurl}/roles`;
  private searchROles = `${Environment.apiurl}/roles/search/role`;
  private deleteDatabase = `${Environment.apiurl}/roles/delete`;
  private uploadFile = `${Environment.apiurl}/roles/uploadFile`;

  constructor(private httpClient: HttpClient) { }

  getRoleList(): Observable<any> {
    return this.httpClient.get(this.baseUrl);
  }

  createRole(role: Object): Observable<Object> {
    console.log("IN SERVICE CREATE ROLE");
    console.log(role);
    let headers = {
      "Content-Type": "application/json"
    };
    let options = {
      headers: headers
    };
    return this.httpClient.post(this.createRoleURL, role, options);
  }

  getRole(id: number): Observable<any> {
    return this.httpClient.get(`${this.getSingleRoleURL}/${id}`);
  }

  updateRole(id: number, value: any): Observable<any> {
    let headers = {
      "Content-Type": "application/json"
    };
    let options = {
      headers: headers
    };
    return this.httpClient.put(`${this.updateRoleURL}/${id}`, value, options);
  }

  deleteRole(id: number): Observable<Object> {
    console.log("IN DELETE SERVICE");
    let headers = {
      "Content-Type": "application/json"
    };
    let options = {
      headers: headers
    };
    return this.httpClient.delete(`${this.deleteRoleURL}/${id}`, options);
  }

  deleteAllRoles() {
    let headers = {
      "Content-Type": "application/json"
    };
    let options = {
      headers: headers
    };
    return this.httpClient.delete(`${this.deleteDatabase}`, options);
  }

  getAllSearchedRoles(searchRole: string, roleName: string): Observable<any> {
    console.log("In getall search Roles");
    let headers = {
      "Content-Type": "application/json"
    };
    let options = {
      headers: headers
    };
    let value = {
      [searchRole]: roleName
    };
    return this.httpClient.post(this.searchROles, value, options);
  }

  uploadFileInServer(fd: FormData): Observable<any> {
    return this.httpClient.post(this.uploadFile, fd);
  }
}
