import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatTable } from '@angular/material';
import { Admin } from '../../app/admin';
import { AdminService } from '../admin.service';
import { AdminDialogBoxComponent } from '../admin-dialog-box/admin-dialog-box.component';
import { AlertService } from '../alert/alert.service';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  selectedFile: File = null;
  roleData: any = [];
  loading: boolean = false;
  datasource: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  displayedColumns: string[] = ['name', 'password', 'role', 'action'];
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(private adminService: AdminService,
    private dialog: MatDialog,
    protected alertService: AlertService,
    private commonService: CommonService) { }

  ngOnInit() {
    this.reloadData();
  }

  reloadData() {

    this.commonService.getRecords("user_info").subscribe(data => {
      this.loading = true;
      if (data.length > 0) {
        this.datasource = new MatTableDataSource<Admin>(data);
        this.roleData = data;
        this.datasource.paginator = this.paginator;
      }
    }, () => {
      this.alertService.error('Unexpected error occurred, please try after sometime :(', this.options);
    });
  }

  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(AdminDialogBoxComponent, {
      width: '60%',
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        this.addRowData(result.data);
      } else if (result.event === 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }

  addRowData(row_obj) {
    console.log("add row data", row_obj);
    if(row_obj === "Username already present") {
      this.alertService.error('Username already present', this.options);
    } else {
      this.reloadData();
      this.alertService.success('Successfully added the user!', this.options);
    }
    
  }

  deleteRowData(row_obj) {
    var indexId = this.datasource.data.findIndex(x => x.id === row_obj.id);
    this.datasource.data.splice(indexId, 1);
    this.datasource.data = [...this.datasource.data];
    this.alertService.success('Successfully deleted the user!', this.options);
  }
}
