import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Environment } from "./envionment";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  private fetchRecords = `${Environment.apiurl}/allrecords`;
  private createRecordUrl = `${Environment.apiurl}/addadmin`;
  private deleteRecordURL = `${Environment.apiurl}/deleterecord`;
  private updateRecordUrl = `${Environment.apiurl}/updaterecord`;
  private deleteDatabase = `${Environment.apiurl}/deleterecords`;
  private uploadRoleFile = `${Environment.apiurl}/roleData/upload`;
  private uploadDependencyFile = `${Environment.apiurl}/dep/upload`;
  private uploadLfthFile = `${Environment.apiurl}/lfth/upload`;
  private uploadTsoFile = `${Environment.apiurl}/tso/upload`;
  private uploadRSFile = `${Environment.apiurl}/rsData/upload`;

  constructor(private httpClient: HttpClient) {}

  getRecords(modelName: Object): Observable<any> {
    let headers = {
      "Content-Type": "application/json",
    };
    let options = {
      headers: headers,
    };
    let body = {
      name: modelName,
    };
    return this.httpClient.post(this.fetchRecords, body, options);
  }

  createRecord(data: object): Observable<any> {
    let headers = {
      "Content-Type": "application/json",
    };
    let options = {
      headers: headers,
    };

    return this.httpClient.post(this.createRecordUrl, data, options);
  }

  updateRecord(id: BigInteger, name: String, data: Object): Observable<any> {
    let headers = {
      "Content-Type": "application/json",
    };
    let options = {
      headers: headers,
    };
    let body = {
      name: name,
      id: id,
      obj: data,
    };
    return this.httpClient.post(this.updateRecordUrl, body, options);
  }

  deleteRecord(id: BigInteger, name: String): Observable<any> {
    let headers = {
      "Content-Type": "application/json",
    };
    let options = {
      headers: headers,
    };
    let body = {
      name: name,
      id: id,
    };
    return this.httpClient.post(this.deleteRecordURL, body, options);
  }

  deleteRecords(name: String): Observable<any> {
    let headers = {
      "Content-Type": "application/json",
    };
    let options = {
      headers: headers,
    };
    let body = {
      name: name,
    };
    return this.httpClient.post(this.deleteDatabase, body, options);
  }

  uploadFileInServer(fd: FormData): Observable<any> {
    return this.httpClient.post(this.uploadRoleFile, fd);
  }

  uploadDependencyFileInServer(fd: FormData): Observable<any> {
    return this.httpClient.post(this.uploadDependencyFile, fd);
  }

  uploadLfThFileInServer(fd: FormData): Observable<any> {
    return this.httpClient.post(this.uploadLfthFile, fd);
  }

  uploadTsoFileInServer(fd: FormData): Observable<any> {
    return this.httpClient.post(this.uploadTsoFile, fd);
  }

  uploadRSFileInServer(fd: FormData): Observable<any> {
    return this.httpClient.post(this.uploadRSFile, fd);
  }
}
