import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment } from './envionment';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private getAllUsers = `${Environment.apiurl}/admin/all_user`;
  private addUser = `${Environment.apiurl}/admin/add`;
  private deleteUserUrl = `${Environment.apiurl}/admin`;

  constructor(private httpClient: HttpClient) { }

  getUsers(): Observable<any> {
    let headers = {
      "username": "user",
      "password": "cb57d475-b15f-4f1c-84b6-8cfb25a63266"
    };
    let options = {
      headers: headers
    };
    return this.httpClient.get(this.getAllUsers, options);
  }

  createUser(userData: Object): Observable<any> {
    let headers = {
      "Content-Type": "application/json"
    };
    let options = {
      headers: headers
    };
    return this.httpClient.post(this.addUser, userData, options);
  }

  deleteUser(id: number): Observable<Object> {
    let headers = {
      "Content-Type": "application/json"
    };
    let options = {
      headers: headers
    };
    return this.httpClient.delete(`${this.deleteUserUrl}/${id}`, options);
  }


}
