import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Environment } from './envionment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'

  public username: String;
  public password: String;
  public role: string;

  constructor(private httpClient: HttpClient) { }

  private findUser = `${Environment.apiurl}/admin`;



  getUser ( name: string ): Observable<any> {
    let headers = {
      "Content-Type": "application/json"
    };
    let body = {
      "name": name
    }
    let options = {
      headers: headers
    };
    return this.httpClient.post(this.findUser, body, options);
  }

  registerSuccessfulLogin(obj) {
    sessionStorage.setItem(this.USER_NAME_SESSION_ATTRIBUTE_NAME, JSON.stringify(obj));
  }

  isUserLoggedIn() {
    let userObj = sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_NAME);
    if (userObj !== undefined && userObj !== null) {
      if (Object.keys(userObj).length === 0) return false
      return true;
    } else {
      return false;
    }

  }

  getLoggedInUserName() {

    let userObj = sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_NAME);
    if (userObj === null) return ''
    return userObj;
  }

  logout() {
    sessionStorage.removeItem(this.USER_NAME_SESSION_ATTRIBUTE_NAME);
    this.username = null;
    this.password = null;
    this.role = null;
  }

}
