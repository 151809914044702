import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser';
  username: string;
  password: string;
  role: string;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(private router: Router,
    private loginService: LoginService,
    protected alertService: AlertService) { }


  ngOnInit() {
    // this.isUserLoggedIn();
  }

  isUserLoggedIn() {
    let isUser = this.loginService.isUserLoggedIn();
    // let isUser = true;
    (isUser === true)? this.router.navigate(["home"]): this.router.navigate(["login"]);
  }

  login (): void {
    this.loginService.getUser(this.username).subscribe((data) => {
      if (data) {
        let userData = data[0];
        if (userData.name === this.username && (atob(userData.pass) === this.password)) {
          this.loginService.registerSuccessfulLogin(userData);
          this.router.navigate(["home"]);
        } else {
          this.alertService.error('Username/Password is wrong.', this.options);
        }
      } else {
        this.alertService.error('User is not present.', this.options);
      }
    }, () => {
      this.alertService.error('User is not present.', this.options);
    });
  }

}
