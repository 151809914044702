import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../admin.service';
import { CommonService } from '../common.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-admin-dialog-box',
  templateUrl: './admin-dialog-box.component.html',
  styleUrls: ['./admin-dialog-box.component.css']
})
export class AdminDialogBoxComponent {

  action: string;
  local_data: any;

  constructor(
    public dialogRef: MatDialogRef<AdminDialogBoxComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData,
    private adminService: AdminService,
    private commonService: CommonService,
    private loginService: LoginService) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  doAction() {
    if (this.local_data.action === "Delete") {
      this.commonService.deleteRecord(this.local_data.id,"user_info").subscribe(() => {
        this.dialogRef.close({ event: this.action, data: this.local_data });
      }, () => {
        alert("Unexpected error occurred, please try after sometime.");
      });
    } else if (this.local_data.action === "Add") {
      this.loginService.getUser(this.local_data.name).subscribe((userData) => {
        if (userData.length > 0) {
          this.dialogRef.close({ event: this.action, data: "Username already present" });
        } else {
          let data = {};
          data["name"] = this.local_data.name;
          data["password"] = btoa(this.local_data.password);
          data["role"] = this.local_data.role;
          this.commonService.createRecord(data).subscribe((newData) => {
            this.dialogRef.close({ event: this.action, data: newData });
          }, () => {
            alert("Unexpected error occurred, please try after sometime.");
          });
        }
      });
      
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }


}
export interface UsersData {
  id: number;
  name: string;
  password: string;
  role: string;
}