import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatTable } from '@angular/material';
import { CommonService } from '../common.service';
import { RoleDialogBoxComponent } from '../role-dialog-box/role-dialog-box.component';
import { AlertService } from '../alert/alert.service';
import { LoginService } from '../login.service';
import * as xlsx from 'xlsx';
import * as fileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-dependency-master',
  templateUrl: './dependency-master.component.html',
  styleUrls: ['./dependency-master.component.css']
})
export class DependencyMasterComponent implements OnInit {

  public isAdmin: boolean = false;
  loading: boolean = false;
  items: boolean = false;
  selectedFile: File = null;
  roleData: any = [];
  tempData: any = [];
  datasource: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  displayedColumns: string[];
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(private dialog: MatDialog,
    protected alertService: AlertService,
    private loginService: LoginService,
    private commonService: CommonService) {
  }

  ngOnInit() {
    this.reloadData();
  }

  getUserRole() {
    let userObj = JSON.parse( this.loginService.getLoggedInUserName() );
    if (userObj.role.toUpperCase() === 'ADMIN') {
      this.isAdmin = true;
      if ( this.displayedColumns != undefined && this.displayedColumns != null) {
        this.displayedColumns = this.displayedColumns.filter(item => item !== "id");
        this.displayedColumns.push( 'action' )
      }
    } else {
      this.displayedColumns = this.displayedColumns.filter(item => item !== "id");
      this.displayedColumns = this.displayedColumns;
    }
    if ( this.displayedColumns != undefined && this.displayedColumns != null) {
        this.items = true;
    }
  }

  reloadData() {
    // this.roleMatrixService.getRoleList().subscribe(data => {
      this.commonService.getRecords('dep_matrix').subscribe(data => {
      this.loading = true;
      if ( data.length > 0 ) {
        this.displayedColumns = Object.keys( data[0] );
        this.getUserRole();
        this.datasource = new MatTableDataSource<Object>(data);
        this.roleData = data;
        this.datasource.paginator = this.paginator;
      } else {
        this.items = false;
        this.datasource = data;
        this.getUserRole();
      }
    }, () => {
      this.alertService.error('Unexpected error occurred, please try after sometime :(', this.options);
    });
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
    (<HTMLInputElement>document.getElementById('upload_file')).disabled = false;
  }

  openDialog(action, obj) {
    obj.action = action;
    obj.name = "dep_matrix";
    const dialogRef = this.dialog.open(RoleDialogBoxComponent, {
      width: '60%',
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Update') {
        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      } else if (result.event == 'DatabaseDelete') {
        this.reloadData();
      }
    });
  }

  updateRowData(row_obj) {
    this.datasource = new MatTableDataSource<Object>(this.roleData.filter((value, key) => {
      if (value.id == row_obj.id) {
        value = row_obj
      }
      return true;
    }));
    this.reloadData();
    this.alertService.success('Successfully updated the record!', this.options);

  }

  deleteRowData(row_obj) {
    var indexId = this.datasource.data.findIndex(x => x.id === row_obj.id);
    this.datasource.data.splice(indexId, 1);
    this.datasource.data = [...this.datasource.data];
    this.alertService.success('Successfully deleted the record!', this.options);
  }


  uploadFile() {
    (<HTMLInputElement>document.getElementById('upload_file')).disabled = true;
    (<HTMLInputElement>document.getElementById('upload_file')).innerHTML = "Uploading...";
    const fd = new FormData();
    fd.append('dependancy', this.selectedFile, this.selectedFile.name);
    this.commonService.uploadDependencyFileInServer(fd).subscribe((data) => {
      (<HTMLInputElement>document.getElementById('upload_file')).innerHTML = "Uploaded";
      if (data.status === 200) {
        setTimeout(() => {
          this.reloadData();
        }, 500);
      }
      if (data.status === 400) {
        this.alertService.error('Unexpected error occurred, please try after sometime :(', this.options);
        (<HTMLInputElement>document.getElementById('upload_file')).disabled = false;
        (<HTMLInputElement>document.getElementById('upload_file')).innerHTML = "Upload";
      }

    }, () => {
      this.alertService.error('Unexpected error occurred, please try after sometime :(', this.options);
      (<HTMLInputElement>document.getElementById('upload_file')).disabled = false;
      (<HTMLInputElement>document.getElementById('upload_file')).innerHTML = "Upload";
    });
  }

  public doFilter = (value: string) => {
    this.datasource.filter = value.trim().toLocaleLowerCase();
  }

  exportAsXLSX () {
    this.tempData = this.roleData;
    this.tempData = this.tempData.filter(function (props) {
      delete props.id;
      return true;
  });
    this.exportAsExcelFile(this.tempData, 'dep_matrix');
  }

  exportAsExcelFile(json: any[], excelFileName: string) {
    const worksheet: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const workbook: xlsx.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    fileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
 }

}
