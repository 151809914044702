import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import {
  MatToolbarModule,
  MatIconModule,
  MatSidenavModule,
  MatListModule,
  MatButtonModule,
  MatTableModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatCardModule,
} from "@angular/material";
import { MatPaginatorModule } from "@angular/material/paginator";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { LayoutModule } from "@angular/cdk/layout";
import { RouterModule } from "@angular/router";
import { AlertModule } from "./alert/alert.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MainNavComponent } from "./main-nav/main-nav.component";
import { RoleMatrixComponent } from "./role-matrix/role-matrix.component";
import { RoleDialogBoxComponent } from "./role-dialog-box/role-dialog-box.component";
import { AdminComponent } from "./admin/admin.component";
import { AdminDialogBoxComponent } from "./admin-dialog-box/admin-dialog-box.component";
import { LoginComponent } from "./login/login.component";
import { DependencyMasterComponent } from "./dependency-master/dependency-master.component";
import { LfThEmailComponent } from "./lf-th-email/lf-th-email.component";
import { TsoComponent } from "./tso/tso.component";
import { RsCodeComponent } from "./rs-code/rs-code.component";

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    RoleMatrixComponent,
    RoleDialogBoxComponent,
    AdminComponent,
    AdminDialogBoxComponent,
    LoginComponent,
    DependencyMasterComponent,
    LfThEmailComponent,
    TsoComponent,
    RsCodeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatCardModule,
    FormsModule,
    AlertModule,
    HttpClientModule,
    LayoutModule,
    RouterModule,
    RouterModule.forRoot([
      { path: "login", component: LoginComponent },
      {
        path: "home",
        component: MainNavComponent,
        children: [
          { path: "admin", component: AdminComponent },
          { path: "roles", component: RoleMatrixComponent },
          { path: "dependency", component: DependencyMasterComponent },
          { path: "emails", component: LfThEmailComponent },
          { path: "tso", component: TsoComponent },
          { path: "rs", component: RsCodeComponent },
        ],
      },
    ]),
  ],
  entryComponents: [RoleDialogBoxComponent, AdminDialogBoxComponent],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
